import { CompanyMemberInviteDrawer } from './CompanyMemberInviteDrawer';
import { ComponentProps, useCallback, useMemo } from 'react';
import { useAtomValue } from 'jotai/index';
import { companyAtom } from '../../../../atoms/auth';
import { joinedTeamsAtom } from '../../../../atoms/firestore/team';
import { usersAtom } from '../../../../atoms/firestore/user';
import { invitedUsersAtom } from '../../../../atoms/firestore/invite';
import { useToast } from '../../../../hooks/useToast';
import { sendInvitationFunction } from '../../../../functions';
import { eventNames, logEvent } from '../../../../analytics';
import { useHistory } from 'react-router-dom';

export const CompanyMemberInviteDrawerWithLogic = () => {
  const history = useHistory();
  const company = useAtomValue(companyAtom);
  const joinedTeams = useAtomValue(joinedTeamsAtom);
  const users = useAtomValue(usersAtom);
  const invitedUsers = useAtomValue(invitedUsersAtom);
  const teams = useMemo(
    () => joinedTeams.map((t) => ({ id: t.id, name: t.name })),
    [joinedTeams]
  );
  const { showToast } = useToast();
  const close = useCallback(
    () => history.push('/settings/company/members'),
    [history]
  );
  const handleSend: ComponentProps<typeof CompanyMemberInviteDrawer>['onSend'] =
    useCallback(
      async ({ email, role, teamId }) => {
        const alreadyExists = users.some((u) => u.email === email);
        if (alreadyExists) {
          return { success: false, error: 'すでに登録されているユーザです' };
        }

        const alreadyInvited = invitedUsers.some((u) => u.email === email);
        if (alreadyInvited) {
          return { success: false, error: 'すでに招待されているユーザです' };
        }

        try {
          await sendInvitationFunction({
            email,
            role,
            teamId,
            companyId: company.id,
          });

          close();

          logEvent(eventNames.invite_user, { role });

          showToast('success', '招待メールを送信しました');

          return { success: true };
        } catch (e) {
          console.error(e);
          return { success: false, error: '予期せぬエラーが発生しました' };
        }
      },
      [users, company.id, close, showToast]
    );
  return (
    <CompanyMemberInviteDrawer
      open={true}
      onOpenChange={close}
      teams={teams}
      onSend={handleSend}
    />
  );
};
